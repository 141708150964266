// @ts-strict-ignore
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import moment from 'moment-timezone';
import { getDefault } from '@/worksheets/worksheetView.utilities';
import { sqMetricsApi } from '@/sdk';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { base64guid, equalsIgnoreCase } from '@/utilities/utilities';
import { WORKSTEP_SCHEMA_VERSION } from './worksteps.constant';
import { DEPRECATED_TOOL_NAMES } from '@/toolSelection/investigate.constants';
import { sqTimezones } from '@/utilities/datetime.constants';
import { momentMeasurementStrings } from '@/datetime/dateTime.utilities';
import { ChartRegion, EMPTY_CHART_REGION } from '@/chart/chart.constants';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '@/tableBuilder/tableBuilder.constants';
import {
  COLUMNS_AND_STATS,
  SERIES_PANEL_REQUIRED_TREND_COLUMNS,
  TREND_PANELS_SORT,
} from '@/trendData/trendData.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { performYAlignment } from '@/utilities/calculations.utilities';
import { EMPTY_XY_REGION, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';

/**
 * A service that manages changes to the schema of workstep state (see configUpgrader for uiConfig upgrades)
 * Since workstep state is just the result of dehydrating all stores this means that any store that makes a
 * backwards incompatible change (e.g. changing a property name, changing a data structure, etc.) must do the following:
 * 1. Create a function named `upgradeX` where X is the current WORKSTEP_SCHEMA_VERSION and add it to the upgraders
 * object.
 * 2. Increase WORKSTEP_SCHEMA_VERSION by one
 */

/**
 * Upgrade the state of a workstep from the specified version to the latest version. Runs the state through a series
 * of transform functions, in order, from the state's version to the specified version.
 *
 * @param {Object} state - The workstep state that is result of dehydrating all the stores.
 * @param {Number} fromVersion - The version number from when the state was created
 * @param {Number} [toVersion=WORKSTEP_SCHEMA_VERSION] - The version number up to which the state will be migrated.
 * Useful only for testing.
 * @returns {Object} The transformed state.
 */
export function apply(state, fromVersion, toVersion?) {
  toVersion = toVersion || WORKSTEP_SCHEMA_VERSION;
  return _.reduce(
    _.range(fromVersion, toVersion, 1),
    (newState, newVersion) => newState.then((state) => upgraders[`upgrade${newVersion}`](state)),
    Promise.resolve(state),
  );
}

/**
 * Migrates `dataView` to `viewKey` on sqWorksheetStore
 */
function upgrade1(state) {
  const dataView = _.get(state, 'stores.sqWorksheetStore.dataView') as string;
  if (_.includes(['trend', 'explore'], dataView)) {
    state.stores.sqWorksheetStore.viewKey = dataView.toUpperCase();
    delete state.stores.sqWorksheetStore.dataView;
  }

  return state;
}

/**
 * Migrates "keywords" to "nameFilter" on sqSearchStore
 */
function upgrade2(state) {
  const keywords = _.get(state, 'stores.sqSearchStore.keywords');
  if (!_.isEmpty(keywords)) {
    state.stores.sqSearchStore.nameFilter = keywords;
    delete state.stores.sqSearchStore.keywords;
  }

  return state;
}

/**
 * Upgrades all 'calculation' properties in sqTrendStore.enabledColumns.CAPSULES to 'formula', changing the
 * contents from '@.' to '$series.'
 */
function upgrade3(state) {
  const path = ['stores', 'sqTrendStore', 'enabledColumns', 'CAPSULES'];
  const capsuleColumns = _.get(state, path);
  _.forEach(capsuleColumns, function (value, key) {
    if (value.calculation) {
      _.set(state, path.concat(key, 'formula'), _.replace(value.calculation, '@', '$series'));
      _.unset(state, path.concat(key, 'calculation'));
    }
  });

  return state;
}

/**
 * Assigns each and every series to its own lane.
 */
function upgrade4(state) {
  const items = _.get(state, 'stores.sqTrendSeriesStore.items') as any;
  const seriesItems = _.reject(items, 'isSeriesFromCapsule');
  const seriesFromCapsuleItems = _.filter(items, 'isSeriesFromCapsule');
  const alignmentOptions = _.times(26, function (idx) {
    return String.fromCharCode(idx + 65);
  });

  _.forEach(seriesItems, function (item: any, idx) {
    if (_.isUndefined(item.lane)) {
      item.lane = idx + 1;
    }

    if (_.isUndefined(item.axisAlign)) {
      item.axisAlign = alignmentOptions[idx];
    }
  });

  _.set(state, 'stores.sqTrendSeriesStore.items', _.concat(seriesItems, seriesFromCapsuleItems));
  return state;
}

/**
 * Migrates to optional 'startTime' column in sqTrendStore
 * Migrates 'anchorId` to `referenceSeries' in sqTrendStore
 * Removes unnecessary properties from enabledColumn definitions
 * Previously, the statistic columns and the property columns would be stored with
 * the entire statistic definition from `TREND_SIGNAL_STATS`. This means that if the
 * statistic column definition changed the data in the store would be stale.
 */
function upgrade5(state) {
  // propertyColumns is used as an indicator that changes are needed because it did not exist in v3
  if (!_.isUndefined(_.get(state, 'stores.sqTrendStore.propertyColumns'))) {
    return state;
  }

  // _.set will create the `CAPSULES` object if no columns were ever enabled
  _.set(state, 'stores.sqTrendStore.enabledColumns.CAPSULES.startTime', true);
  const enabledColumns = _.get(state, 'stores.sqTrendStore.enabledColumns.CAPSULES');

  _.forEach(_.filter(enabledColumns, _.isObject), function (column) {
    enabledColumns[column.key] = {
      key: column.key,
      statisticKey: _.join(_.split(column.key, '.', 2), '.'), // statistic.max.1234 -> statistic.max
      referenceSeries: column.anchorId,
    };
  });

  state.stores.sqTrendStore.propertyColumns = {};

  return state;
}

/**
 * Migrates tools that were in RESULTS mode to the new overview tool since RESULTS mode is gone.
 */
function upgrade6(state) {
  const displayMode = _.get(state, 'stores.sqInvestigateStore.displayMode');
  if (displayMode === 'RESULTS') {
    state.stores.sqInvestigateStore.displayMode = 'NEW';
    state.stores.sqInvestigateStore.activeTool = 'overview';
  }

  return state;
}

/**
 * Add an empty autoUpdate state.
 */
function upgrade7(state) {
  const durationStore = _.get(state, 'stores.sqDurationStore') as any;
  if (!_.isEmpty(durationStore) && _.isUndefined(durationStore.autoUpdate)) {
    state.stores.sqDurationStore.autoUpdate = {};
  }

  return state;
}

/**
 * Expands the display range for ScatterPlot Capsules view to the investigate range.
 */
function upgrade8(state) {
  const durationStore = _.get(state, 'stores.sqDurationStore');
  const worksheetView = _.get(state, 'stores.sqWorksheetStore.viewKey');
  const scatterPlotMode = _.get(state, 'stores.sqScatterPlotStore.plotMode');
  if (
    !_.isEmpty(durationStore) &&
    worksheetView === 'SCATTER_PLOT' &&
    !_.isEmpty(scatterPlotMode) &&
    scatterPlotMode === 'CAPSULES'
  ) {
    state.stores.sqDurationStore.displayRange.start = _.get(state, 'stores.sqDurationStore.investigateRange.start');
    state.stores.sqDurationStore.displayRange.end = _.get(state, 'stores.sqDurationStore.investigateRange.end');
  }

  return state;
}

/**
 * Migrates custom histogram bin colors to new store name.
 */
function upgrade9(state) {
  const colorConfig = _.get(state, 'stores.sqTrendTableStore.colorConfig');
  if (_.isEmpty(colorConfig)) {
    return state;
  }

  state.stores.sqTrendTableStore.binConfig = _.mapValues(colorConfig, function (value) {
    return { color: value };
  });

  _.unset(state, 'stores.sqTrendTableStore.colorConfig');
  return state;
}

/**
 * Migrates from selectedIds to selectedCapsules. Renames editingCapsuleSetId to editingId for consistency
 */
function upgrade10(state) {
  const capsuleStore = _.get(state, 'stores.sqTrendCapsuleStore');

  if (!_.isEmpty(capsuleStore)) {
    if (state.stores.sqTrendCapsuleStore.editingCapsuleSetId) {
      state.stores.sqTrendCapsuleStore.editingId = state.stores.sqTrendCapsuleStore.editingCapsuleSetId;
      delete state.stores.sqTrendCapsuleStore.editingCapsuleSetId;
    }

    delete state.stores.sqTrendCapsuleStore.selectedIds;
  }

  return state;
}

/**
 * Migrates the search store mode
 */
function upgrade11(state) {
  _.forEach(['sqSearchStore', 'sqModalSearchStore'], (store) => {
    if (_.get(state, `stores.${store}.isAssetBrowsing`) && _.get(state, `stores.${store}.currentAsset`)) {
      _.set(state, `stores.${store}.mode`, 'tree');
    } else if (_.get(state, `stores.${store}.nameFilter`)) {
      _.set(state, `stores.${store}.mode`, 'search');
    }

    _.unset(state, `stores.${store}.isAssetBrowsing`);
  });

  return state;
}

/**
 * Updates Duration.store to rename 'offset' to 'displayRangeEndOffset' and updates the MODE if the auto update isn't
 * enabled.
 */
function upgrade12(state) {
  if (!_.isEmpty(state.stores.sqDurationStore)) {
    if (!_.isNil(state.stores.sqDurationStore.autoUpdate.enabled)) {
      if (state.stores.sqDurationStore.autoUpdate.enabled === false) {
        state.stores.sqDurationStore.autoUpdate.mode = 'OFF';
      }
      delete state.stores.sqDurationStore.autoUpdate.enabled;
    }
  }
  return state;
}

/**
 * Updates the BaseSignalStore to rename 'samples' to 'sampleDisplayOption'.
 */
function upgrade13(state) {
  const items = _.get(state, 'stores.sqTrendSeriesStore.items') as any;
  const seriesItems = _.reject(items, 'isSeriesFromCapsule');
  const seriesFromCapsuleItems = _.filter(items, 'isSeriesFromCapsule');
  const updatedSeriesItems = [];
  _.forEach(seriesItems, function (item: any) {
    if (_.has(item, 'samples')) {
      item.sampleDisplayOption = item.samples;
      item = _.omit(item, 'samples');
    }
    updatedSeriesItems.push(item);
  });

  _.set(state, 'stores.sqTrendSeriesStore.items', _.concat(updatedSeriesItems, seriesFromCapsuleItems));

  return state;
}

/**
 * Previously, when the manual condition tool was opened the capsules were stored in the manual condition store. This
 * upgrade step moves them to the sqCapsuleGroup store that previously didn't exist.
 */
function upgrade14(state) {
  if (!_.isEmpty(state.stores.sqManualConditionStore)) {
    if (!_.isNil(state.stores.sqManualConditionStore.capsules)) {
      _.set(state.stores, ['sqCapsuleGroupStore', 'capsules'], state.stores.sqManualConditionStore.capsules);
      delete state.stores.sqManualConditionStore.capsules;
    }
  }
  return state;
}

/**
 * Removes the `displayFavorites` property (which has been replaced in favor of `displaySelector` This will
 * effectively reset to the "All" view
 */
function upgrade15(state) {
  if (!_.isEmpty(state.stores.sqWorkbenchStore)) {
    if (!_.isNil(_.get(state, 'stores.sqWorkbenchStore.displayFavorites'))) {
      delete state.stores.sqWorkbenchStore.displayFavorites;
    }
  }
  return state;
}

/**
 * Updates the sqReportStore duration object to a number
 */
function upgrade16(state) {
  if (!_.isEmpty(state.stores.sqReportStore)) {
    const path = ['stores', 'sqReportStore', 'dateVariables'];
    const dateVariables = _.get(state, path);

    _.forEach(dateVariables, function (dateVariable) {
      if (!_.isNil(dateVariable.auto)) {
        if (!_.isNil(dateVariable.auto.duration)) {
          if (!_.isNil(dateVariable.auto.duration.value)) {
            const newValue = moment.duration(
              dateVariable.auto.duration.value,
              momentMeasurementStrings(dateVariable.auto.duration.units),
            );

            dateVariable.auto.duration = newValue.valueOf();
          }
        }
      }
    });

    _.set(state, 'stores.sqReportStore.dateVariables', dateVariables);
  }

  return state;
}

/**
 * Updates sqPeriodicConditionStore timezone property from offset to name
 */
function upgrade17(state) {
  const offset = _.get(state.stores, 'sqPeriodicConditionStore.timezone');
  if (offset) {
    state.stores.sqPeriodicConditionStore.timezone = _.get(sqTimezones.findMostCommonTimeZone(offset), 'name');
  }

  return state;
}

/**
 * Removes ancillaries from signal stores, the ancillaries used to be persisted. This migrates them
 * to a displayedAncillaryItemIds property. In the future the ancillaries will be loaded dynamically
 * if they exist in this array.
 */
function upgrade18(state) {
  const displayedAncillaryItemIdsMapping = {};
  const sqTrendSeriesStore = _.get(state.stores, 'sqTrendSeriesStore');
  const sqTrendScalarStore = _.get(state.stores, 'sqTrendScalarStore');
  _.forEach([sqTrendSeriesStore, sqTrendScalarStore], (sqSignalStore) => {
    if (!sqSignalStore || !sqSignalStore.items) {
      return;
    }

    const [ancillaries, items] = _.partition<any>(sqSignalStore.items, 'isChildOf');
    sqSignalStore.items = items;
    _.forEach(ancillaries, (item) => {
      const displayedAncillaryItemIds = _.get(displayedAncillaryItemIdsMapping, item.isChildOf, []);
      displayedAncillaryItemIds.push(item.id);
      _.set(displayedAncillaryItemIdsMapping, item.isChildOf, displayedAncillaryItemIds);
    });
  });

  // Note: scalars can be ancillaries, but cannot have ancillaries
  _.forEach(_.get(sqTrendSeriesStore, 'items'), (item) => {
    const displayedAncillaryItemIds = displayedAncillaryItemIdsMapping[item.id];
    if (displayedAncillaryItemIds) {
      item.displayedAncillaryItemIds = displayedAncillaryItemIds;
    }
  });

  return state;
}

/**
 * Updates topic date ranges to have the "auto" property which came with R20 and live docs.
 */
function upgrade19(state) {
  const dateVariables = _.get<any[]>(state.stores, 'sqReportStore.dateVariables', []);
  _.forEach(dateVariables, (variable) => {
    if (!_.has(variable, 'auto')) {
      variable.auto = { enabled: false };
    }
  });

  if (!_.isEmpty(dateVariables)) {
    state.stores.sqReportStore.dateVariables = dateVariables;
  }

  return state;
}

/**
 * Removes duplicate items from the details pane after a backend upgrade that replaces duplicates with the keeper
 * in worksteps. See CRAB-13226.
 */
function upgrade20(state) {
  if (_.has(state.stores, 'sqTrendSeriesStore.items')) {
    state.stores.sqTrendSeriesStore.items = _.uniqBy(state.stores.sqTrendSeriesStore.items, 'id');
  }

  if (_.has(state.stores, 'sqTrendScalarStore.items')) {
    state.stores.sqTrendScalarStore.items = _.uniqBy(state.stores.sqTrendScalarStore.items, 'id');
  }

  if (_.has(state.stores, 'sqTrendCapsuleSetStore.items')) {
    state.stores.sqTrendCapsuleSetStore.items = _.uniqBy(state.stores.sqTrendCapsuleSetStore.items, 'id');
  }

  return state;
}

/**
 * Removes series from capsules that were stored in the trend series store. Before Seeq R21.0.40.00 series from
 * capsule segments needed to be preserved in the workstep because they represented whether one of the capsule
 * time "eyeballs" was selected. Since the "eyeball" mechanism was removed (CRAB-11107), we don't need to the
 * segments to maintain that state.
 */
function upgrade21(state) {
  if (_.has(state.stores, 'sqTrendSeriesStore.items')) {
    state.stores.sqTrendSeriesStore.items = _.reject(state.stores.sqTrendSeriesStore.items, 'isChildOf');
  }

  return state;
}

/**
 * Renames sqTrendStore.hideDimmedCapsules to sqTrendStore.hideUnselectedItems
 */
function upgrade22(state) {
  if (_.has(state.stores, 'sqTrendStore.hideDimmedCapsules')) {
    state.stores.sqTrendStore.hideUnselectedItems = state.stores.sqTrendStore.hideDimmedCapsules;
    delete state.stores.sqTrendStore.hideDimmedCapsules;
  }

  return state;
}

/**
 * Adds an id to scorecard columns.
 */
function upgrade23(state) {
  if (_.has(state.stores, 'sqTrendMetricStore.scorecardColumns')) {
    state.stores.sqTrendMetricStore.scorecardColumns = _.map(
      state.stores.sqTrendMetricStore.scorecardColumns,
      (column) => ({ id: base64guid(), ...column }),
    );
  }

  return state;
}

/**
 * Upgrades empty view-region to the new version of it
 */
function upgrade24(state) {
  const oldXyRegion = _.pick(EMPTY_CHART_REGION, ['xMin', 'xMax', 'yMin', 'yMax']);
  _.forEach(['selectedRegion', 'viewRegion'], (region) => {
    if (_.isEqual(_.get(state.stores, `sqScatterPlotStore.${region}`), oldXyRegion)) {
      state.stores.sqScatterPlotStore[region] = EMPTY_CHART_REGION;
    }
  });

  return state;
}

/**
 * Migrates item detail panes that used the standardDeviation stat to the stdDev stat.
 */
function upgrade25(state) {
  const stdDev = _.get(state.stores, "sqTrendStore.enabledColumns.SERIES['statistics.standardDeviation']");
  if (stdDev) {
    delete state.stores.sqTrendStore.enabledColumns.SERIES['statistics.standardDeviation'];
    state.stores.sqTrendStore.enabledColumns.SERIES['statistics.stdDev'] = true;
  }
  return state;
}

/**
 * Changes SCORECARD view to TABLE and moves scorecard properties to table builder. If in TABLE view and items are
 * selected it unselects all items so that user will see all the metrics, not just the selected ones (to preserve
 * previous behavior where selection did not influence the table). Moves the scorecardOrder to lane now that order
 * is dictated by the details pane.
 */
function upgrade32(state) {
  const viewKey = _.get(state.stores, 'sqWorksheetStore.viewKey');
  if (viewKey === 'SCORECARD') {
    state.stores.sqWorksheetStore.viewKey = WORKSHEET_VIEW.TABLE;
    let maxLane = 0;
    const selectedIds = [];
    _.forEach(
      [
        'sqTrendSeriesStore',
        'sqTrendCapsuleSetStore',
        'sqTrendMetricStore',
        'sqTrendScalarStore',
        'sqTrendTableStore',
        'sqTrendCapsuleStore',
      ],
      (storeName) => {
        _.forEach(_.get(state.stores[storeName], 'items'), (item, index) => {
          if (storeName !== 'sqTrendMetricStore' && item.lane > maxLane) {
            maxLane = item.lane;
          }

          if (item.selected) {
            selectedIds.push(item.id);
            state.stores[storeName].items[index].selected = false;
          }
        });
      },
    );
    if (selectedIds.length) {
      state.stores.sqWorksheetStore.selectedIdsForView = {
        [getDefault().selectedItemsRealm]: selectedIds,
      };
    }

    _.forEach(state.stores.sqTrendMetricStore?.items, (item, index) => {
      state.stores.sqTrendMetricStore.items[index].lane = item.scorecardOrder + maxLane + 1;
    });

    if (!_.isUndefined(state.stores.sqTrendStore?.panelSorters)) {
      state.stores.sqTrendStore.panelSorters.SERIES = TREND_PANELS_SORT.SERIES;
    }
  }

  if (_.isUndefined(state.stores.sqTableBuilderStore)) {
    state.stores.sqTableBuilderStore = {};
  }

  if (_.has(state.stores, 'sqTrendMetricStore.scorecardColumns')) {
    state.stores.sqTableBuilderStore.columns = state.stores.sqTrendMetricStore.scorecardColumns;
    delete state.stores.sqTrendMetricStore.scorecardColumns;
  }

  if (_.has(state.stores, 'sqTrendMetricStore.scorecardHeaders')) {
    state.stores.sqTableBuilderStore.headers = state.stores.sqTrendMetricStore.scorecardHeaders;
    delete state.stores.sqTrendMetricStore.scorecardHeaders;
  }

  return state;
}

/**
 * Sets the activeTool to "overview" for deprecated tools
 */
function upgrade33(state) {
  if (_.includes(DEPRECATED_TOOL_NAMES, state?.stores?.sqInvestigateStore?.activeTool)) {
    state.stores.sqInvestigateStore.activeTool = 'overview';
  }

  return state;
}

/**
 * Upgrades the table builder store state to the new structure which supports both simple and condition tables
 */
function upgrade34(state) {
  if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
    state.stores.sqTableBuilderStore.mode = TableBuilderMode.Condition;
    if (
      _.has(state.stores, 'sqTableBuilderStore.columns') &&
      !_.has(state.stores, `sqTableBuilderStore.columns.${TableBuilderMode.Condition}`)
    ) {
      state.stores.sqTableBuilderStore.columns = {
        [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.columns,
        [TableBuilderMode.Simple]: _.map(SERIES_PANEL_REQUIRED_TREND_COLUMNS, (key) => ({ key })),
      };
    }
    if (
      _.has(state.stores, 'sqTableBuilderStore.isTransposed') &&
      !_.has(state.stores, `sqTableBuilderStore.isTransposed.${TableBuilderMode.Condition}`)
    ) {
      state.stores.sqTableBuilderStore.isTransposed = {
        [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.isTransposed,
        [TableBuilderMode.Simple]: false,
      };
    }
  }
  return state;
}

/**
 * Upgrades the table builder store state to include the new variations in header options
 */
function upgrade35(state) {
  if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
    // skip if the table already have the new header format.
    // This upgrade was not executed until WORKSTEP_SCHEMA_VERSION = 37
    if (
      _.has(state.stores, 'sqTableBuilderStore.headers') &&
      _.isNil(state.stores.sqTableBuilderStore.headers[TableBuilderMode.Simple])
    ) {
      state.stores.sqTableBuilderStore.headers = {
        [TableBuilderMode.Condition]: state.stores.sqTableBuilderStore.headers,
        [TableBuilderMode.Simple]: {
          type: TableBuilderHeaderType.StartEnd,
          format: 'lll',
        },
      };
    }
  }
  return state;
}

/**
 * Switches from condition to simple mode if the table builder store contains simple metrics and migrate the
 * content which can be displayed in the simple table. If the old table contains only simple metrics the user
 * should see no change after migration.
 * The switch to simple mode is done if there is at least one simple metric.
 */
function upgrade36(state) {
  const worksheetView = _.get(state, 'stores.sqWorksheetStore.viewKey');
  const tableStore = _.get(state, 'stores.sqTableBuilderStore');

  // Convert capsule property Value header to Value (Original)
  const capsuleProperty =
    tableStore && _.get(state.stores, `sqTableBuilderStore.headers[${TableBuilderMode.Condition}].property`);
  if (equalsIgnoreCase(capsuleProperty, SeeqNames.CapsuleProperties.Value)) {
    state.stores.sqTableBuilderStore.headers[
      TableBuilderMode.Condition
    ].property = `${SeeqNames.CapsuleProperties.Value} (Original)`;
  }

  if (tableStore && tableStore.mode === TableBuilderMode.Condition) {
    const trendMetricItems = _.get(state, 'stores.sqTrendMetricStore.items');
    const allMetricPromises = _.map(trendMetricItems, (metric) => sqMetricsApi.getMetric({ id: metric.id }));

    return Promise.all(allMetricPromises).then((allMetrics: any) => {
      const [simpleMetrics, nonSimpleMetrics] = _.partition(
        allMetrics,
        (metric) => metric.data?.processType === ProcessTypeEnum.Simple,
      );
      const selectedSimpleMetrics = _.filter(
        trendMetricItems,
        (trendMetric) =>
          _.some(simpleMetrics, (metric) => metric.data.id === trendMetric.id) && trendMetric.selected === true,
      );
      const selectedNonSimpleMetrics = _.filter(
        trendMetricItems,
        (trendMetric) =>
          _.some(nonSimpleMetrics, (metric) => metric.data.id === trendMetric.id) && trendMetric.selected === true,
      );

      if (_.isEmpty(selectedSimpleMetrics) && !_.isEmpty(selectedNonSimpleMetrics)) {
        // nothing to change. Keep the existing table in condition mode.
      } else if (!_.isEmpty(simpleMetrics)) {
        tableStore.mode = TableBuilderMode.Simple;
        tableStore.headers[TableBuilderMode.Simple] = tableStore.headers[TableBuilderMode.Condition];

        // if isTransposed was not initialized until now, add the field in the store.
        if (_.has(state.stores, 'sqTableBuilderStore.isTransposed')) {
          tableStore.isTransposed[TableBuilderMode.Simple] = tableStore.isTransposed[TableBuilderMode.Condition];
        } else {
          tableStore.isTransposed = {
            [TableBuilderMode.Condition]: false,
            [TableBuilderMode.Simple]: false,
          };
        }

        let nameColumnFound = false;
        // transfer only the first 'name' column and all free text columns to simple mode
        tableStore.columns[TableBuilderMode.Simple] = _.chain(tableStore.columns[TableBuilderMode.Condition])
          .filter((column) => {
            if (column.type === COLUMNS_AND_STATS.name.key) {
              if (!nameColumnFound) {
                nameColumnFound = true;
                return true;
              }
              return false;
            } else {
              return true;
            }
          })
          .map((column) => {
            switch (column.type) {
              case COLUMNS_AND_STATS.name.key:
                return {
                  ..._.pick(column, ['backgroundColor', 'header']),
                  key: COLUMNS_AND_STATS.name.key,
                };
              case TableBuilderColumnType.Text:
                return {
                  ..._.pick(column, ['backgroundColor', 'header', 'cells', 'type']),
                  key: column.id,
                };
              default:
                return column;
            }
          })
          .value();

        // if we have also condition metrics (mixed table), keep existing columns
        if (_.isEmpty(nonSimpleMetrics)) {
          tableStore.columns[TableBuilderMode.Condition] = [];
        }

        // add metric value column as the last column and set headerOverridden flag
        tableStore.columns[TableBuilderMode.Simple].push({
          key: 'metricValue',
          headerOverridden: true,
        });

        // Select all metrics if none is selected and we have other items in the details pane.
        // When upgrading from condition to simple mode we want to see only metrics in the table by default and no
        // other item.
        const noMetricSelected = !_.some(trendMetricItems, { selected: true });
        const otherItemsPresent =
          _.get(state, 'stores.sqTrendCapsuleSetStore.items.length') ||
          _.get(state, 'stores.sqTrendScalarStore.items.length') ||
          _.get(state, 'stores.sqTrendSeriesStore.items.length');

        const allMetrics = _.get(state, 'stores.sqTrendMetricStore.items');
        const allSelectedMetricIds = _.map(_.filter(allMetrics, 'selected'), 'id');

        if (otherItemsPresent) {
          // The old table displayed only metrics.
          if (worksheetView === 'TABLE') {
            // Keep the selections for trend view, but unselect all non metric in the table view
            const allConditions = _.get(state, 'stores.sqTrendCapsuleSetStore.items');
            const allScalars = _.get(state, 'stores.sqTrendScalarStore.items');
            const allSeries = _.get(state, 'stores.sqTrendSeriesStore.items');

            const allSelectedConditionIds = _.map(_.filter(allConditions, 'selected'), 'id');
            const allSelectedScalarIds = _.map(_.filter(allScalars, 'selected'), 'id');
            const allSelectedSeriesIds = _.map(_.filter(allSeries, 'selected'), 'id');

            const allSelectedIds = _.concat(
              allSelectedConditionIds,
              allSelectedScalarIds,
              allSelectedSeriesIds,
              allSelectedMetricIds,
            );
            state.stores.sqWorksheetStore.selectedIdsForView = {
              TREND: allSelectedIds,
            };

            _.forEach(allConditions, (item) => {
              item.selected = false;
            });
            _.forEach(allScalars, (item) => {
              item.selected = false;
            });
            _.forEach(allSeries, (item) => {
              item.selected = false;
            });

            if (noMetricSelected) {
              // select all metrics.
              _.forEach(trendMetricItems, (item) => {
                item.selected = true;
              });
            }
          } else {
            // If no metric selected, select all metrics for table view and do not touch the selections of the current
            // view. Else set selected metrics as selected in the table view
            if (noMetricSelected) {
              state.stores.sqWorksheetStore.selectedIdsForView = {
                TABLE: _.map(trendMetricItems, 'id'),
              };
            } else {
              state.stores.sqWorksheetStore.selectedIdsForView = {
                TABLE: allSelectedMetricIds,
              };
            }
          }
        }
      }
      return state;
    });
  }
  return state;
}

/**
 * Adds the default style to all headers in simple and condition mode. and converts columns to the new format.
 */
function upgrade37(state) {
  const tableStore = _.get(state, 'stores.sqTableBuilderStore');
  if (!_.isUndefined(tableStore) && !_.isEmpty(tableStore)) {
    if (_.has(tableStore, ['columns', TableBuilderMode.Simple])) {
      _.forEach(tableStore.columns[TableBuilderMode.Simple], (column, index) => {
        state.stores.sqTableBuilderStore.columns[TableBuilderMode.Simple][index] = column.isCustomProperty
          ? {
              ..._.omit(column, ['isCustomProperty']),
              type: TableBuilderColumnType.Property,
              headerTextAlign: 'center',
              headerTextStyle: ['bold'],
              textColor: column.backgroundColor && tinycolor(column.backgroundColor).isDark() ? '#fff' : undefined,
            }
          : {
              ...column,
              headerTextAlign: 'center',
              headerTextStyle: ['bold'],
              textColor: column.backgroundColor && tinycolor(column.backgroundColor).isDark() ? '#fff' : undefined,
            };
      });
    }

    if (_.has(tableStore, ['columns', TableBuilderMode.Condition])) {
      _.forEach(state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition], (column, index) => {
        state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition][index] =
          column.type === TableBuilderColumnType.Text
            ? {
                ..._.omit(column, ['id', 'shortTitle']),
                key: column.id || column.key,
                headerTextAlign: 'center',
                headerTextStyle: ['bold'],
                textColor: column.backgroundColor && tinycolor(column.backgroundColor).isDark() ? '#fff' : undefined,
              }
            : {
                ..._.omit(column, ['id', 'shortTitle', 'type']),
                key: column.type || column.key,
                headerTextAlign: 'center',
                headerTextStyle: ['bold'],
                textColor: column.backgroundColor && tinycolor(column.backgroundColor).isDark() ? '#fff' : undefined,
              };
      });
    }
  }

  return state;
}

/**
 * Accommodates the new ability for table builder to include conditions. Selects only metrics if user is in
 * condition table builder to ensure it looks the same.
 */
function upgrade38(state) {
  const tableStoreMode = _.get(state, 'stores.sqTableBuilderStore.mode');
  const viewKey = _.get(state.stores, 'sqWorksheetStore.viewKey');
  if (viewKey === WORKSHEET_VIEW.TABLE && tableStoreMode === TableBuilderMode.Condition) {
    const trendMetricItems = _.get(state, 'stores.sqTrendMetricStore.items');
    const metricSelected = _.some(trendMetricItems, 'selected');
    const conditionItems = _.get(state, 'stores.sqTrendCapsuleSetStore.items');
    if (!metricSelected && !_.isEmpty(conditionItems)) {
      _.forEach(trendMetricItems, (item, index) => {
        state.stores.sqTrendMetricStore.items[index].selected = true;
      });
    } else if (metricSelected && _.some(conditionItems, 'selected')) {
      _.forEach(conditionItems, (item, index) => {
        state.stores.sqTrendCapsuleSetStore.items[index].selected = false;
      });
    }
  }

  return state;
}

/**
 * Adds the new sort and filter criteria to existing table builder worksteps.
 */
function upgrade39(state) {
  if (!_.isUndefined(state.stores.sqTableBuilderStore) && !_.isEmpty(state.stores.sqTableBuilderStore)) {
    if (_.isNil(state.stores.sqTableBuilderStore.itemFilters)) {
      state.stores.sqTableBuilderStore.itemFilters = {};
    }
    if (_.isNil(state.stores.sqTableBuilderStore.itemSorts)) {
      state.stores.sqTableBuilderStore.itemSorts = {};
    }
  }
  return state;
}

/**
 * Removes condition and signal entries from the conditionToSeriesGrouping if those conditions are not present
 * in the details pane. Previously there were cases where conditions and signals that were removed from the details
 * pane were not also removed from the conditionToSeriesGrouping. See CRAB-25536.
 */
function upgrade42(state) {
  const conditionToSeriesGrouping = _.get(state, 'stores.sqWorksheetStore.conditionToSeriesGrouping');

  if (!_.isEmpty(conditionToSeriesGrouping)) {
    const detailsPaneConditionIds = _.chain(state).get('stores.sqTrendCapsuleSetStore.items').map('id').value();

    const detailsPaneSignalIds = _.chain(state).get('stores.sqTrendSeriesStore.items').map('id').value();

    const cleanedConditionToSeriesGrouping = _.chain(conditionToSeriesGrouping)
      .pickBy((signals, conditionId) => _.includes(detailsPaneConditionIds, conditionId))
      .mapValues((signals) => _.intersection(signals, detailsPaneSignalIds))
      .value();

    _.set(state, 'stores.sqWorksheetStore.conditionToSeriesGrouping', cleanedConditionToSeriesGrouping);
  }

  return state;
}

function upgrade43(state) {
  if (_.has(state.stores, 'sqTrendCapsuleStore')) {
    state.stores.sqTrendCapsuleStore.capsulesPerPage = 30;
  }

  return state;
}

/**
 * Sets conditionTable.isTransposed to false on existing worksteps to prevent changing existing tables
 */
function upgrade44(state) {
  if (_.isUndefined(state.stores.sqTableBuilderStore?.isTransposed?.[TableBuilderMode.Condition])) {
    _.set(state, `stores.sqTableBuilderStore.isTransposed.${[TableBuilderMode.Condition]}`, false);
  }
  return state;
}

/**
 * Changes xSeries and ySeries in the scatter plot store to xSignal and ySignals
 */
function upgrade45(state) {
  if (!_.isEmpty(state.stores?.sqScatterPlotStore?.xSeries)) {
    state.stores.sqScatterPlotStore.xSignal = state.stores.sqScatterPlotStore.xSeries;
  }
  delete state.stores?.sqScatterPlotStore?.xSeries;

  if (!_.isEmpty(state.stores?.sqScatterPlotStore?.ySeries)) {
    state.stores.sqScatterPlotStore.ySignals = [state.stores.sqScatterPlotStore.ySeries];
  }
  delete state.stores?.sqScatterPlotStore?.ySeries;

  return state;
}

/**
 * Changes the format of viewRegion and selectedRegion in the scatter plot store to support multiple y signals
 */
function upgrade46(state) {
  const oldRegionToNewRegion: (region: ChartRegion) => XYPlotRegion = (region = EMPTY_CHART_REGION) => {
    const yId = state.stores.sqScatterPlotStore.ySignals[0].id;
    const newRegion = {
      x: {
        min: region.xMin,
        max: region.xMax,
      },
      ys: {},
    };

    if (!_.isNil(region.yMin) && !_.isNil(region.yMax) && !_.isNil(yId)) {
      newRegion.ys[yId] = {
        min: region.yMin,
        max: region.yMax,
      };
    }

    return newRegion;
  };

  if (
    (state.stores.sqScatterPlotStore?.ySignals?.length ?? 0 > 0) &&
    state.stores.sqScatterPlotStore?.xSignal !== null
  ) {
    if (
      !_.isEqual(state.stores.sqScatterPlotStore?.selectedRegion, EMPTY_CHART_REGION) &&
      !state.stores.sqScatterPlotStore?.selectedRegion?.x
    ) {
      _.set(
        state,
        'stores.sqScatterPlotStore.selectedRegion',
        oldRegionToNewRegion(state.stores.sqScatterPlotStore?.selectedRegion),
      );
    } else if (_.isEqual(state.stores.sqScatterPlotStore?.selectedRegion, EMPTY_CHART_REGION)) {
      _.set(state, 'stores.sqScatterPlotStore.selectedRegion', EMPTY_XY_REGION);
    }

    if (
      !_.isEqual(state.stores.sqScatterPlotStore?.viewRegion, EMPTY_CHART_REGION) &&
      !state.stores.sqScatterPlotStore?.viewRegion?.x
    ) {
      _.set(
        state,
        'stores.sqScatterPlotStore.viewRegion',
        oldRegionToNewRegion(state.stores.sqScatterPlotStore?.viewRegion),
      );
    } else if (_.isEqual(state.stores.sqScatterPlotStore?.viewRegion, EMPTY_CHART_REGION)) {
      _.set(state, 'stores.sqScatterPlotStore.viewRegion', EMPTY_XY_REGION);
    }
  } else {
    _.set(state, 'stores.sqScatterPlotStore.selectedRegion', EMPTY_XY_REGION);
    _.set(state, 'stores.sqScatterPlotStore.viewRegion', EMPTY_XY_REGION);
  }

  return state;
}

function upgrade47(state) {
  if (!_.isEmpty(state?.stores?.sqTrendStore?.capsuleAlignment)) {
    // Set all scalars to be visible
    const scalars = state.stores?.sqTrendScalarStore?.items ?? [];
    _.set(
      state,
      'stores.sqTrendScalarStore.items',
      _.map(scalars, (scalar) => ({
        ...scalar,
        visible: true,
      })),
    );

    // Align the series respective to those that share their lane
    const capsuleSeries = state.stores?.sqTrendSeriesStore?.capsuleSeries;
    const newCapsuleSeries = [];
    _.forEach(state.stores?.sqTrendStore?.uniqueLanes, (lane) => {
      const yAlignmentValues = performYAlignment(_.filter(capsuleSeries, { lane }));

      _.forEach(yAlignmentValues, (yAlignment) => {
        const series = _.find(capsuleSeries, ['id', yAlignment.id]);
        newCapsuleSeries.push({
          ...series,
          yAlignment: _.omit(yAlignment, 'id', 'autoDisabled'),
          visible: !yAlignment.autoDisabled,
          autoDisabled: yAlignment.autoDisabled,
        });
      });
    });

    _.set(state, 'stores.sqTrendSeriesStore.capsuleSeries', newCapsuleSeries);

    delete state.stores.sqTrendStore.capsuleAlignment;
  }

  return state;
}

/**
 * Converts the similarity column to a regular properties column
 * @param state
 */
function upgrade48(state) {
  if (state?.stores?.sqTrendStore?.enabledColumns?.CAPSULES?.similarity) {
    delete state.stores.sqTrendStore.enabledColumns.CAPSULES.similarity;
    _.set(state, 'stores.sqTrendStore.enabledColumns.CAPSULES["properties.Similarity"]', true);
    _.set(state, 'stores.sqTrendStore.propertyColumns.CAPSULES["properties.Similarity"]', {
      key: 'properties.Similarity',
      propertyName: 'Similarity',
      uomKey: 'propertiesUOM.Similarity',
    });
  }

  return state;
}

/**
 * Upgrades condition tables with capsule properties by changing Property columns for condition tables to be
 * CapsuleProperty type.
 * @param state
 */
function upgrade49(state) {
  if (_.isArray(state?.stores?.sqTableBuilderStore?.columns?.[TableBuilderMode.Condition])) {
    _.forEach(state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition], (column, index) => {
      if (column?.type === TableBuilderColumnType.Property) {
        state.stores.sqTableBuilderStore.columns[TableBuilderMode.Condition][index].type =
          TableBuilderColumnType.CapsuleProperty;
      }
    });
  }

  return state;
}

/**
 * Assigns lanes to conditions and shift other item lanes
 * Shifts the lane target for all custom lane labels by the number of condition lanes added
 * @param state
 */
function upgrade50(state) {
  const conditionItems = _.sortBy(_.get(state, 'stores.sqTrendCapsuleSetStore.items'), 'conditionLane');

  let conditionsEndLane = 0;
  _.forEach(conditionItems, (item, index) => {
    const newLane = +index + 1;
    item.lane = newLane;
    conditionsEndLane = newLane;
    if (_.has(item, 'conditionLane')) {
      delete item.conditionLane;
    }
  });

  if (conditionsEndLane === 0) {
    return state;
  }

  _.set(state, 'stores.sqTrendCapsuleSetStore.items', conditionItems);

  // Shift other item lanes
  const otherItemStores = [
    'sqTrendSeriesStore',
    'sqTrendScalarStore',
    'sqTrendTableStore',
    'sqTrendMetricStore',
  ] as const;

  const updateOtherStoreItems = (store: typeof otherItemStores[number]) => {
    const itemsPath = `stores.${store}.items`;
    const items = _.get(state, itemsPath);

    const itemsWithUpdatedLane = _.chain(items)
      .map((item) => (_.isInteger(item.lane) ? { ...item, lane: item.lane + conditionsEndLane } : item))
      .value();

    _.set(state, itemsPath, itemsWithUpdatedLane);
  };

  _.forEach(otherItemStores, updateOtherStoreItems);

  // Shift lane target for custom lane labels
  const customLabels = _.get(state, 'stores.sqTrendStore.labelDisplayConfiguration.customLabels', []);
  if (!_.isEmpty(customLabels)) {
    const shiftedCustomLabels = customLabels.map((customLabel) =>
      customLabel.location === 'lane'
        ? { ...customLabel, target: customLabel.target + conditionsEndLane }
        : customLabel,
    );
    _.set(state, 'stores.sqTrendStore.labelDisplayConfiguration.customLabels', shiftedCustomLabels);
  }

  return state;
}

/**
 * Upgrades chartSettings to accept key for columns and id for rows instead of indexes.
 * @param state
 */
function upgrade51(state) {
  if (state?.stores?.sqTableBuilderStore?.chartView?.settings) {
    _.forEach(['columns', 'categoryColumns'], (setting) => {
      if (_.isArray(state.stores.sqTableBuilderStore.chartView.settings[setting])) {
        _.forEach(state.stores.sqTableBuilderStore.chartView.settings[setting], (columnIndex, index) => {
          if (_.isNumber(columnIndex) && state.stores.sqTableBuilderStore.columns.simple[columnIndex]) {
            state.stores.sqTableBuilderStore.chartView.settings[setting].splice(
              index,
              1,
              state.stores.sqTableBuilderStore.columns.simple[columnIndex].key,
            );
          }
        });
      }
    });
    // Old version of rows was indices that pointed to items in the details pane. Since the details pane cannot be
    // reconstructed the setting is emptied.
    if (_.every(state.stores.sqTableBuilderStore.chartView.settings.rows, _.isNumber)) {
      _.set(state, 'stores.sqTableBuilderStore.chartView.settings.rows', []);
    }
  }

  return state;
}

function upgrade52(state) {
  const conditions = _.get(state, 'stores.sqTrendCapsuleSetStore.items');
  if (conditions?.length > 0) {
    _.set(
      state,
      'stores.sqTrendCapsuleSetStore.items',
      _.map(conditions, (condition) => ({ ...condition, lineWidth: condition.lineWidth ?? 1 })),
    );
  }

  return state;
}

/**
 * In R44 and R45 there was a bug where if the user opened the modal to add a property column to either the Series
 * or Capsules panel and then clicked cancel it would still add an empty property. While the bug was fixed, an
 * upgrade to remove the invalid state was not written and in R58 that invalid state would cause the worksheet to
 * fail to load (CRAB-36970). This upgrade removes the invalid state.
 *
 * @param state
 */
function upgrade53(state) {
  _.unset(state, ['stores', 'sqTrendStore', 'enabledColumns', 'CAPSULES', 'properties.undefined']);
  _.unset(state, ['stores', 'sqTrendStore', 'propertyColumns', 'CAPSULES', 'properties.undefined']);
  _.unset(state, ['stores', 'sqTrendStore', 'enabledColumns', 'SERIES', 'properties.undefined']);
  _.unset(state, ['stores', 'sqTrendStore', 'propertyColumns', 'SERIES', 'properties.undefined']);

  return state;
}

const upgrade41 = _.identity; // CRAB-27022 added an upgrade to 53, so this is necessary for forward compatibility
const upgrade40 = _.identity; // CRAB-27022 added an upgrade to 53, so this is necessary for forward compatibility
// CRAB-24258: A backport added upgrade26 to R50.3.0 which prevented a different upgrade26 function in R51.0.0
// and greater from executing. We fix this by ensuring the logic for upgrades 26 - 31 is idempotent and moving
// those upgrades to later workstep schema versions to ensure they all execute.
const upgrade26 = _.identity;
const upgrade27 = _.identity;
const upgrade28 = _.identity;
const upgrade29 = _.identity;
const upgrade30 = _.identity;
const upgrade31 = _.identity;

const upgraders = {
  upgrade1,
  upgrade2,
  upgrade3,
  upgrade4,
  upgrade5,
  upgrade6,
  upgrade7,
  upgrade8,
  upgrade9,
  upgrade10,
  upgrade11,
  upgrade12,
  upgrade13,
  upgrade14,
  upgrade15,
  upgrade16,
  upgrade17,
  upgrade18,
  upgrade19,
  upgrade20,
  upgrade21,
  upgrade22,
  upgrade23,
  upgrade24,
  upgrade25,
  upgrade26,
  upgrade27,
  upgrade28,
  upgrade29,
  upgrade30,
  upgrade31,
  upgrade32,
  upgrade33,
  upgrade34,
  upgrade35,
  upgrade36,
  upgrade37,
  upgrade38,
  upgrade39,
  upgrade40,
  upgrade41,
  upgrade42,
  upgrade43,
  upgrade44,
  upgrade45,
  upgrade46,
  upgrade47,
  upgrade48,
  upgrade49,
  upgrade50,
  upgrade51,
  upgrade52,
  upgrade53,
};
